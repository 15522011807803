import axios from "axios";
const baseUrlAPI='https://www.icameventmanagement.co.za'
//const baseUrlAPI='http://ec2-54-226-122-235.compute-1.amazonaws.com:8000'
let BASE_URL = baseUrlAPI+'/api'
let BASE_URL2 = baseUrlAPI
let BASE_URL3 = baseUrlAPI+'/api'
let BASE_URL5 = 'http://ec2-3-84-8-100.compute-1.amazonaws.com:8005/api'
export const MainUrl = baseUrlAPI+"/api"
export const imgUrl = baseUrlAPI+"/media/null"

export const getDataFromApi = (getUrl, is_api1 = "", is_api2 = "", is_api5 = "", isExternal = "") => {
    if (!isExternal) {
        getUrl = getUrl.indexOf("mocky") !== -1 ? getUrl : (getUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${getUrl}` : `${BASE_URL}/${getUrl}`);
    }
    const resp = axios.get(getUrl, '')
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};

// call the api (PUT) endpoint internal system
export const putDataFromApi = (putUrl, model, is_api1 = "", is_api2 = "", is_api5 = "", is_formdata) => {
    putUrl = putUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${putUrl}` : `${BASE_URL}/${putUrl}`;
    var config = {
        method: 'put',
        url: putUrl,
        data: model,
    }
    if (is_formdata) {
        config['headers'] = {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('accessToken')}`
        }
    }
    const resp = axios(config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};

// call the api (POST) endpoint internal system
export const postDataFromApi = async (postUrl, model, is_api1 = "", is_formdata = "", is_api2 = "", is_api5 = "") => {
    postUrl = postUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${postUrl}` : `${BASE_URL}/${postUrl}`;
    if (model) {
        model['loginId'] = parseInt(localStorage.getItem('currentUser'))
        // model.push({
        //     loginId:parseInt(localStorage.getItem('currentUser'))
        // })
    }
    else {
        model = {
            loginId: parseInt(localStorage.getItem('currentUser'))
        }
    }
    var config = {
        method: 'post',
        url: postUrl,
        data: model,
        /*  headers: { 'content-type': 'multipart/form-data' }, */
    }
    /*  if(is_formdata){
         config['headers']['Content-Type']='multipart/form-data'
     } */
    const resp = axios(config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};

// call the api (PATCH) endpoint internal system
export const patchDataFromApi = (patchUrl, model, is_api1 = "", is_api2 = "", is_api5 = "") => {
    patchUrl = patchUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${patchUrl}` : `${BASE_URL}/${patchUrl}`;
    var config = {
        method: 'patch',
        url: patchUrl,
        data: model,
    }
    /* if(is_formdata){
        config['headers']= {...data.getHeaders() }
    } */
    const resp = axios(config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};

// call the api (DELETE) endpoint internal system
export const deleteDataFromApi = (deleteUrl, model, is_api1 = "", is_api2 = "", is_api5 = "") => {
    deleteUrl = deleteUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${deleteUrl}` : `${BASE_URL}/${deleteUrl}`;
    const resp = axios.delete(deleteUrl, { data: model })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};

// call the api (POST) endpoint internal system
export const getEventsByPriority = async (postUrl, model, is_api1 = "", is_formdata = "", is_api2 = "", is_api5 = "") => {
    postUrl = postUrl.startsWith("/") ? `${is_api1 ? BASE_URL2 : is_api2 ? BASE_URL3 : is_api5 ? BASE_URL5 : BASE_URL}${postUrl}` : `${BASE_URL}/${postUrl}`;
    
    var config = {
        method: 'post',
        url: postUrl,
        data: model,
        /*  headers: { 'content-type': 'multipart/form-data' }, */
    }
   
    const resp = axios(config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            var data={
                status:500,
                data:[]
            }
            return data
        });
    return resp;
};