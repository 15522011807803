import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
// import Main_header from "./Components/Header/Header";

import Main_footer from "./Components/Header/footer";
import UserManagement from "./Components/UserManagement/index";
import style from "./Assets/css/style.css";
import style2 from "./Assets/css/style2.css";
import style3 from "./Assets/css/style3.css";
import MyRoutes from "./MyRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import TestDrawer from "./Components/Header/TestDrawer";
import Menu from "./Components/Header/TestDrawer";
import Sidebar from "./Components/Header/TestDrawer";


function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const authurls = ["/", "/login", "/forgot-password"];
  const authVerify = () => {
    const authurls = ["/", "/login", "/forgot-password"];
    if (authurls.includes(pathname)) {
      if (localStorage.getItem("accessToken") != null) {
        navigate("/dashboard");
      }
    } else {
      if (
        localStorage.getItem("accessToken") == null ||
        localStorage.getItem("accessToken") == undefined ||
        localStorage.getItem("accessToken") == ""
      ) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    document.title = "ICAM";
    authVerify();
  }, []);
  if (authurls.includes(pathname)) {
    return (
      <div className="App">
        <MyRoutes />
      </div>
    );
  } else {
    return (
      <div className="App grey-bg">
      {/* <Main_header authVerify={authVerify} /> */}
        {/* <TestDrawer> */}
           {/* <Main_header  /> */}
        <Sidebar  authVerify={authVerify} >
        <MyRoutes />
        <Main_footer /> 
        </Sidebar>

        {/* </TestDrawer> */}
      </div>
    );
  }
}

export default App;
 