import React, { useState, useEffect, useRef } from "react";
import Stack from "@mui/material/Stack";

import { useTheme, styled } from "@mui/system";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Icon,
  TableRow,
  Button,
  Tooltip,
  Modal,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Autocomplete } from "@mui/lab";
import Eye from "../../Assets/images/Vector.png";
import Dots from "../../Assets/images/dots.png";
import Trash from "../../Assets/images/trash.png";
import Pencil from "../../Assets/images/Pencil.png";
import { useNavigate } from "react-router-dom";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import {
  eventworkflowList,
  eventworkflowGet,
  eventworkflowDelete,
  coachingeventworkflowDelete,
  eventworkflowAdd,
  coachingworkflowStepList,
  coachingcoachingworkflowStepList,
  coachingTypeFilter,
  coachingFilter,
  addWorkFlow,
  workflowStepListApi,
  coachingcreateWorkFlow,
  eventTypeList,
  companyManagementList,
  eventDesigner
} from "../../Services/api";
import {
  getDataFromApi,
  deleteDataFromApi,
  postDataFromApi,
  putDataFromApi
} from "../../Services/CommonServices";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AlertMessage from "../commoncomponent/AlertMessage";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import DialogForm from './DialogForm';
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseBtn from "../../Assets/images/close.png";
import plusIcon from "../../Assets/images/plusIcon.png";
import { Loader } from "../../Common/Loader";
// import TabPanel from '@material-ui/lab/TabPanel';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const AutoComplete = styled(Autocomplete)(() => ({
  width: 300,
  marginBottom: "16px",
}));
const vehicleopt = [
  { id: 1, label: "Vehicle 1" },
  { id: 2, label: "Vehicle 2" },
  { id: 3, label: "Vehicle 3" },
];
const modelopt = [
  { id: 1, label: "Model 1" },
  { id: 2, label: "Model 2" },
  { id: 3, label: "Model 3" },
];


const EventType = () => {
  const navigate = useNavigate();
  const [customSteps, setcustomSteps] = useState([]);

  const [eventDetails, seteventDetails] = useState([]);
  const [workflowDetails, setWorkFlowDetails] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOpens, setDeleteOpens] = useState(false);
  const [workdeleteOpen, setWorkdeleteOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [alert, setalert] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [alertType, setalertType] = useState("");
  const [value, setValue] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchData, setSearchData] = React.useState([]);
  const [showDrpdwn, setShowDrpdwn] = React.useState('')
  const [dialogOpen, setDialogOpen] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([])
  const [dialogMode, setDialogMode] = useState('add');
  const [data, setData] = useState()
  const [clickedValue, setClickedValue] = useState("COACHING")
  const [getEventType, setGetEventType] = useState([]);
  const [getCoaching, setGetCoaching] = useState([])
  const [getMedium, setMedium] = useState([])
  const [getCoachingDetails, setCoachingDetails] = useState([])
  const [id, setId] = useState("")
  const [workflowStepList, setworkflowStepList] = useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [workstepmode, setWorkStepmode] = React.useState("");
  const [isView, setIsView] = React.useState(false);
  const [viewCoaching, setViewCoaching] = useState()
  const [getCoachingAction, setCoachingAction] = useState([])
  const [categoryopt, setCategoryOpt] = useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const [selectedIndex, setselectedIndex] = useState("");
  const [addmoreEventOpen, addmoreEventsetOpen] = React.useState(false);
  const [editmore, seteditmore] = React.useState(false);
  const [editmoreId, seteditmoreId] = React.useState("");
  const [usergroups, setusergroups] = useState([]);
  const [usergroupsOpt, setusergroupsOpt] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [loading, setLoad] = React.useState(true);
  const [formData, setFormData] = useState({
    step_name: "",
    description: "",
    step_description: "",
    pdf_required: false,
    nextstep_message: "",
    notify_by: "",
    // upload_document: "",
    ctime: "",
    uptime: "",
    status: "",
    company_name: "",
    user_groups: [],
    user_raised: "",
    user_completed: "",
    step_coaching_users: [],
    step_users: ""
    // default: false,
  });

  const [formdata, setFormdata] = useState({
    category: "",
    event_type: "",
    priority: "",
    company_name: "",
    workflow_steps: [],
    coaching_action_group: {},
  });

  const userRole = localStorage.getItem('userRole')

  const addmoreEventhandleClose = () => {
    addmoreEventsetOpen(false);
    seteditmore(false);
    seteditmoreId("");
  };
  const getEventDesignerFilters = async () => {
    var loginId = localStorage.getItem("currentUser");
    const response = await getDataFromApi(eventDesigner + loginId + "/");
    if (response && response.status == 200 && response.data != null) {
      // let category = []
      const category = response?.data?.category.map((category) => {
        return { id: category, label: category };
      });
      setCategoryOpt(category);
      const eventType = response?.data?.event_type.map((event) => {
        return { id: event, label: event };
      });}}
     
      // response = await getDataFromApi(eventDesigner);
      // if (response && response.status == 200 && response.data != null) {
      // const vehicle = response?.data?.vehicle_group.map((vehicle) => {
      //   return { id: vehicle, name: vehicle }
      // })
      // setVehicleGroup(vehicle)
      //   setVehicleGroup(response.data)
      // }
 

     

   
  const getCompanies = async () => {
  
    const response = await getDataFromApi(companyManagementList);
    if (response && response.status == 200 && response.data != null) {
      var companyopt = [];
      response.data.map((company, i) => {
        companyopt.push({
          'id': company.company_name,
          'label': company.company_name,
        })
      })
      if (userRole.toLowerCase() === 'company user' || userRole.toLowerCase() === 'company admin') {
        setCompanyOptions([{ label: userCompany, id: userCompany }])
        setFormData((formData) => ({
          ...formData,
          ['company_name']: userCompany,
        }));
      }
      else {
        setCompanyOptions(companyopt)
      }
    }
  };
  const userCompany = localStorage.getItem('userCompany')
  useEffect(() => {
  
    getCompanies()
    getEventDesignerFilters();
    // getallEvents();
    //  getgetVehicleGroup();
    // getDriverGroup();
    // getEventDesignerFilters();
  }, []);

  const getCoachingTypeFilters = async () => {
    const payload = {
      coaching_type: clickedValue,
      event_type: "",
      medium: ""
    }
    const response = await getDataFromApi(eventTypeList)
    if (response && response.status == 200 && response.data.length > 0) {
      var userdata = response.data;
      setCoachingDetails(userdata)
    }
  };
  const getCoachingSearchFilter = async () => {
    let payload = {
      "event_type": formdata.event_type ? formdata.event_type : "",
      "medium": formdata.medium ? formdata.medium : "",
      "coaching_type": clickedValue
    }
    if (formdata.event_type === '' && formdata.medium) {
      payload['event_type'] = ""
      payload['medium'] = formdata.medium
    } else if (formdata.event_type && formdata.medium === '') {
      payload['event_type'] = formdata.event_type
      payload['medium'] = ""
    }
    const response = await postDataFromApi(coachingTypeFilter, payload)
    if (response && response?.status == 200 && response?.data?.length > 0) {
      var userdata = response?.data;
      setCoachingDetails(userdata)
    } else {
      setCoachingDetails([])
    }
  };

  const getusergroup = async () => {
    var loginId = localStorage.getItem("currentUser");
    // + loginId + "/"
    const response = await getDataFromApi(
      coachingcreateWorkFlow + "?id=" + loginId
    );
    if (response && response.status == 200 && response.data != null) {
      setusergroups(response.data);
      var sd = response.data;
      var groupss = [];
      sd.map((opt) => {
        var jd = [];
        jd["id"] = opt.id;
        jd["label"] = opt.step_name;
        groupss.push(jd);
      });
      const a = [...groupss, ...selectedValues];
      const uniqueArray = a.filter((item) => {
        return a.lastIndexOf(item) == a.indexOf(item);
      });
      setusergroupsOpt(uniqueArray);
      // setusergroupsOpt(groupss);
    }
  };

  const handleAddClick = () => {
    setDialogOpen(true);
    setDialogMode('add');
    setFormData((formData) => ({
      ...formData,
      ["event_step_name"]: "",
      ["description"]: "",
      ["step_coaching_users"]: "",
      ["step_name"]: "",
      ['company_name']: "",
      // ['pdf_required']: userdata.pdf_required ? "True" : "False",
      //       ["workflow_name"]: userdata.workflow_name,
      //       ["vehicle_group"]: userdata.vehicle_group
      //         ? userdata.vehicle_group.id
      //         : "",
      //       ["driver_group"]: userdata.driver_group
      //         ? userdata.driver_group.id
      //         : "",
      //       ["event_type"]: userdata.event_type,
      //       ["callto"]: userdata.callto,
      //       ["informdriver"]: userdata.informdriver,

      //       ["nameRadio"]: userdata.nameRadio,

      //       ["category"]: userdata.category.toUpperCase(),

      //       // ["default"]: userdata.default,
    }));
    // setFormData({
    //   name: '',
    //   // Initialize other fields here for the 'add' mode
    // });
  };

  const addmoreEventhandleOpen = (index) => {
    setselectedIndex(index);
    setFormdata((formData) => ({
      ...formData,
      ["event_step_name"]: "",
      ["Description"]: "",
      ["nameRadio"]: "",
      ["user_usergroup"]: "",
    }));
    addmoreEventsetOpen(true);
  };

  const addCoachandleOpen = async (id) => {
    setAddOpen(true);
    setalert(false);
    setIsView(false)
    setFormdata((formData) => ({
      ...formData,
      ['workflow_name']: '',
      ['event_type']: '',
      ['coaching_type']: '',
      ['medium']: '',
      ['coaching_action_group']: {},
    }));
    setcustomSteps([])
  };


  const handleViewClick = async (item) => {
    // setData(item)
    // setFormData(item);
    var response = "";
    response = await getDataFromApi(coachingworkflowStepList + item + "/");

    if (response && response.status == 200 && response.data != null) {
      // setsingleUser(response.data);
      if (item) {
        var userdata = response.data;
        const multipleUsergroups = []
        userdata.step_coaching_users.map((allusergroups, i) => {
          multipleUsergroups.push(allusergroups.id)
        })
        setDialogMode('view');
        setFormData((formData) => ({
          ...formData,
          ["event_step_name"]: userdata.event_step_name,
          ["description"]: userdata.description,
          ["step_coaching_users"]: multipleUsergroups,
          ["step_name"]: userdata.step_name,
          ['company_name']: userdata.company_name,
          // ['pdf_required']: userdata.pdf_required ? "True" : "False",
          //       ["workflow_name"]: userdata.workflow_name,
          //       ["vehicle_group"]: userdata.vehicle_group
          //         ? userdata.vehicle_group.id
          //         : "",
          //       ["driver_group"]: userdata.driver_group
          //         ? userdata.driver_group.id
          //         : "",
          //       ["event_type"]: userdata.event_type,
          //       ["callto"]: userdata.callto,
          //       ["informdriver"]: userdata.informdriver,

          //       ["nameRadio"]: userdata.nameRadio,

          //       ["category"]: userdata.category.toUpperCase(),

          //       // ["default"]: userdata.default,
        }));
        setDialogOpen(true);
        //   userdata.workflow_steps &&
        //     userdata.workflow_steps.map((opt) => {
        //       customSteps.push(opt);
        //     });
        //   setaddedSteps((chk) => ({
        //     ...chk,
        //     checkboxes: userdata.workflow_steps,
        //   }));

        //   setTimeout(() => {
        //     set_is_edit_loaded(true);
        //   }, 10);
      }
    }
  };

  const handleEditClick = async (item) => {
    setData(item)
    // setFormData(item);
    var response = "";
    response = await getDataFromApi(coachingworkflowStepList + item + "/");

    if (response && response.status == 200 && response.data != null) {
      // setsingleUser(response.data);
      if (item) {
        var userdata = response.data;
        const multipleUsergroups = []
        userdata.step_coaching_users.map((allusergroups, i) => {
          multipleUsergroups.push(allusergroups.id)
        })


        setDialogMode('edit');
        setFormData((formData) => ({
          ...formData,
          ["event_step_name"]: userdata.event_step_name,
          ["description"]: userdata.description,
          ["step_coaching_users"]: multipleUsergroups,
          ["step_name"]: userdata.step_name,
          ['company_name']: userdata.company_name,
          // ['pdf_required']: userdata.pdf_required ? "True" : "False",
          //       ["workflow_name"]: userdata.workflow_name,
          //       ["vehicle_group"]: userdata.vehicle_group
          //         ? userdata.vehicle_group.id
          //         : "",
          //       ["driver_group"]: userdata.driver_group
          //         ? userdata.driver_group.id
          //         : "",
          //       ["event_type"]: userdata.event_type,
          //       ["callto"]: userdata.callto,
          //       ["informdriver"]: userdata.informdriver,

          //       ["nameRadio"]: userdata.nameRadio,

          //       ["category"]: userdata.category.toUpperCase(),

          //       // ["default"]: userdata.default,
        }));
        setDialogOpen(true);
        //   userdata.workflow_steps &&
        //     userdata.workflow_steps.map((opt) => {
        //       customSteps.push(opt);
        //     });
        //   setaddedSteps((chk) => ({
        //     ...chk,
        //     checkboxes: userdata.workflow_steps,
        //   }));

        //   setTimeout(() => {
        //     set_is_edit_loaded(true);
        //   }, 10);
      }
    }


    // setDialogOpen(true);
    // setDialogMode('edit');
    // setFormData(item);
  };
  const handleView = async (id, type = "", e) => {
    e.preventDefault()
    setWorkStepmode(type)
    setId(id)
    setIsEdit(false)
    setIsView(false)
   
    try {
      const response = await getDataFromApi(eventTypeList + id + "/");
      if (response && response.status == 200) {
        setViewCoaching(response.data)

        // setViewOpen(true);
        setAddOpen(true);
        if (type == "edit") {
          setIsEdit(true)
        }
        else {
          setIsView(true)
        }
        setFormdata((formData) => ({
          ...formData,
          ['workflow_name']: response.data.coaching_workflow_name,
          ['event_type']: response?.data?.event_type,
          ['priority']: response.data.priority,
          ['company_name']: response.data.company_name,
          ['category']: response.data.category,
        }));

        var userdata = response.data;
        var editcustomsteps = []
        var customids = []
        userdata.workflow_steps_list &&
          userdata.workflow_steps_list.map((opt) => {
            editcustomsteps.push(opt);
            customids.push(opt.id);
          });
        setcustomSteps(editcustomsteps)

      }
    } catch (err) {
    }
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    getworkFlowEvent()

  };

  const handleSubmitStep = async (event) => {
    var newcustomSteps = customSteps;
    var selectedWorkFlow = getSelectedItem(formdata.user_usergroup, usergroups);
    var newUserGroup = usergroupsOpt.filter((singlegrop, i) => {
      return formdata.user_usergroup != singlegrop.id
    })

    if (editmore) {
      var index = newcustomSteps
        .map((x) => {
          return x.id;
        })
        .indexOf(editmoreId);
      newcustomSteps[index] = selectedWorkFlow;
    } else {
      newcustomSteps.splice(selectedIndex, 0, selectedWorkFlow);
    }
    setusergroupsOpt(newUserGroup)
    setcustomSteps(newcustomSteps);
    addmoreEventhandleClose();

    return;
    // if (formdata.nameRadio && formdata.nameRadio == "True") {
    //   var pdf_required = "True";
    // } else {
    //   var pdf_required = "False";
    // }
    // var newformdata = {
    //   step_name: formdata.event_step_name,
    //   description: formdata.Description,
    //   pdf_required: pdf_required,
    //   step_message: "",
    //   user_usergroup: { id: 1 },
    // };

    // const formData = new FormData();
    // formData.append("step_name", formdata.event_step_name);
    // formData.append("description", formdata.Description);
    // formData.append("pdf_required", pdf_required);
    // formData.append("step_message", "");
    // formData.append("user_usergroup", formdata.user_usergroup);

    // var response = "";
    // if (editmore) {
    //   formData.append('nextstep_message', '')
    //   response = await putDataFromApi(
    //     eventworkflowStepAdd + editmoreId + "/",
    //     formData
    //   );
    // } else {
    //   response = await postDataFromApi(eventworkflowStepAdd, formData);
    // }
    // if (response && response.status == 200) {
    //   if (editmore) {
    //     setalertMessage("Event Workflow steps Updated successfully");
    //   } else {
    //     setalertMessage("Event Workflow steps Added successfully");
    //   }

    //   setalert(true);
    //   setalertType("success");
    //   setsamePage(true);
    //   getworkflowStepList();
    //   if (editmore) {
    //     var index = customSteps
    //       .map((x) => {
    //         return x.id;
    //       })
    //       .indexOf(editmoreId);
    //     customSteps[index] = response.data;
    //   } else {
    //     // customSteps.push(response.data)
    //     customSteps.splice(selectedIndex, 0, response.data);
    //     //customSteps.insert(selectedIndex, response.data)
    //   }
    // } else {
    //   setalertMessage("error...");
    //   setalert(true);
    //   setalertType("error");
    //   setsamePage(true);
    //   getworkflowStepList();
    // }
  };
  const coachandleClose = () => {
    setAddOpen(false);
    setalert(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setSearchQuery('');
    setSearchData([]);
    setShowDrpdwn('')
    getworkFlowEvent()
  };

  function confirm() {
    setalert(false);
    setAddOpen(false);

  }

  function handleDeleteClose() {
    setDeleteOpen(false);
    setDeleteOpens(false);
    setDeleteId("");
  }
  function handleWorkDeleteClose() {
    // setDeleteOpen(false);
    setWorkdeleteOpen(false);
    setDeleteId("");
  }
  function handleDeleteworkOpen(id) {
    // setDeleteOpen(true);
    setWorkdeleteOpen(true);
    setDeleteId(id);
  }
  function handleDeleteOpen(id) {
    setDeleteOpen(true);
    setDeleteId(id);
  }
  function handleDeleteOpens(id) {
    console.log("fdf",id)
    setDeleteOpens(true);
    setDeleteId(id);
  }
  function formdataValueChange(e) {
    var value = e.target.value.trimStart()
    // var value = "";
    if (e.target.type == "checkbox") {
      value = e.target.checked ? true : false;
    } else {
      value = e.target.value.trimStart();
    }
    setFormdata((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
  }
  function getSelectedItem(
    id,
    data = [],
    multiple = "",
    label = "",
    is_inputvalue = ""
  ) {
    // setTesting(true)
    if (multiple) {
      var items = [];
      id = typeof id === "string" ? id.split(",") : id;
      if (Array.isArray(id)) {
        id.map((sid, i) => {
          const item = data.find((opt) => {
            if (label) {
              if (opt.label == sid) return opt;
            } else {
              if (opt.id == sid) return opt;
            }
          });
          if (item) {
            items.push(item);
          }
        });
        return items
      }
    } else {
      const item = data.find((opt) => {
        if (label) {
          if (opt.label == id) return opt;
        } else {
          if (opt.id == id) return opt;
        }
      });
      return item || null;
    }
  }
  function changeDropdownValue(type, value) {
    console.log("Selected value:", value); // Debug: Check the structure of value

    let processedValue;

    if (type === "event_type") {
        // Handle event_type specifically
        processedValue = value; // No changes needed for event_type
    } else if (type === "category" || type === "event_type") {
        // Handle array values (multiple selections)
        processedValue = value ? value.map(item => item.id) : [];
    } else if (type === "coaching_action_group") {
        // Handle single or array values
        processedValue = value || "";
    } else if (type === "priority") {
        // Handle single value for priority
        processedValue = value ? value.id : ""; // Adjust based on how your value is structured
    } else {
        // Default case for other types
        processedValue = value ? value.id : "";
    }

    console.log("Processed value:", processedValue); // Debug: Check processed value

    // Update form data
    setFormdata(formData => ({
        ...formData,
        [type]: processedValue,
    }));
}


  const handleDeleteWork = async (e) => {
    var response = "";
    var newForom = {
      id: delete_id,
    };
    response = await deleteDataFromApi(coachingeventworkflowDelete, newForom);
    if (response && response.status == 200) {
      // var index = customSteps
      //   .map((x) => {
      //     return x.id;
      //   })
      //   .indexOf(delete_id);

      // customSteps.splice(index, 1);
      // getcoachingworkflowStepList();
      getworkFlowEvent();
      setDeleteId("");
      setWorkdeleteOpen(false);
      setalertMessage("Step Deleted successfully");
      setalert(true);
      setalertType("success");
    } else {
      setalertMessage("error...");
      setalert(true);
      setalertType("error");
      setDeleteId("");
      setWorkdeleteOpen(false);
    }
  };

  const handleDeleteConfirm = async (e) => {
    var query = "";
    var response = "";
    response = await deleteDataFromApi(eventTypeList + delete_id + "/");
    console.log("response",response)
    if (response && response.status == 204) {
      setDeleteId("");
      setDeleteOpens(false);
      setalertMessage(response.data.message);
      setalert(true);
      setalertType("success");
      
      // geteventDetails("delete");
      getCoachingTypeFilters()
    } else {
      setalertMessage("error...");
      setalert(true);
      setalertType("error");
      setDeleteId("");
      setDeleteOpen(false);
      geteventDetails("delete");
    }
  };
  const handleDeleteconfirm = async (e) => {
    var newcustomSteps = customSteps;
    var index = newcustomSteps
      .map((x) => {
        return x.id;
      })
      .indexOf(delete_id);
    var removableStep = newcustomSteps[index]
    var newAddedOptions = usergroupsOpt;
    newAddedOptions.push({
      id: removableStep.id,
      label: removableStep.step_name,
    })
    const uniqueArray = newAddedOptions.filter((item) => {
      return newAddedOptions.lastIndexOf(item) == newAddedOptions.indexOf(item);
    });
    setusergroupsOpt(uniqueArray)
    // setusergroupsOpt(newAddedOptions)
    newcustomSteps.splice(index, 1);
    setcustomSteps(newcustomSteps);
    setDeleteId("");
    setDeleteOpen(false);
    // var response = "";
    // response = await deleteDataFromApi(eventworkflowDelete + delete_id + "/");

    // if (response && response.status == 200 && response.data != null) {
    //   var index = customSteps
    //     .map((x) => {
    //       return x.id;
    //     })
    //     .indexOf(delete_id);

    //   customSteps.splice(index, 1);
    //   getworkflowStepList();
    //   setDeleteId("")
    //   setDeleteOpen(false)
    //   setalertMessage('Step Deleted successfully')
    //   setalert(true)
    //   setalertType('success')
    // } else {
    //   setalertMessage('error...')
    //   setalert(true)
    //   setalertType('error')
    //   setDeleteId("")
    //   setDeleteOpen(false)
    // }
  };


  const handleSubmitSearch = async (event, type) => {
    var formData = new FormData();
    var vehicleGroup = "";
    var workflow = [];
    customSteps &&
      customSteps.map((opt, i) => {
        var st = { id: opt.id };
        workflow.push(st);
      });
    setIsEdit(false)
    const coaching_array = {
      id: formdata.coaching_action_group
    }
    console.log("DFdf",formdata)
    const category = Array.isArray(formdata.category) ? formdata.category.join(',') : formdata.category.join(',');
    var newformdata = {
      event_type: typeof formdata.event_type === "string"
        ? formdata?.event_type
        : typeof formdata.event_type === "object"
          ? formdata?.event_type?.join(",")
          : "",
      priority: formdata.priority,
      category:category,
      company_name:formdata.company_name
    };

    var response = "";
    let eventid = id
    if (isEdit) {
      // newformdata.id = eventid
      response = await putDataFromApi(
        eventTypeList + eventid +"/" ,
        newformdata
      );
      if (response &&( response.status == 200 || response.status ==201)) {
        setalertMessage("Event Workflow Updated successfully");
        setalert(true);
        setalertType("success");

        // getCoachingEvents();
        getCoachingTypeFilters()
      } else {
        setalertMessage("error...");
        setalert(true);
        setalertType("error");
      }
    } else {
      response = await postDataFromApi(eventTypeList, newformdata);
      if (response && response.status == 201) {
        setalertMessage("Event Workflow Added successfully");
        setalert(true);
        setalertType("success");
        var zero = ""
        setFormdata((formData) => ({
          ...formData,
          [type]: zero,
        }));
        // setAddOpen(false);
        getCoachingTypeFilters()
      } else {
        setalertMessage("error...");
        setalert(true);
        setalertType("error");
      }
      // response = await getDataFromApi(addWorkFlow);
    }
  };

  const geteventDetails = async (from = "") => {
    setLoad(true)
    var query = "";
    var loginId = localStorage.getItem("currentUser");
    const response = await getDataFromApi(addWorkFlow);
    if (response && response.status == 200 && response.data != null) {
      response.data.sort((a, b) => b.id - a.id);
      seteventDetails(response.data);
      if (!from) {
        // getdatatable();
      }
      setLoad(false)
    }
    else {
      setLoad(false)
    }
  };

  const getworkFlowEvent = async () => {
    var loginId = localStorage.getItem("currentUser");
    // + loginId + "/"
    const response = await getDataFromApi(coachingworkflowStepList + "?id=" + loginId);
    if (response && response.status == 200 && response.data != null) {
      response.data.sort((a, b) => b.id - a.id);

      setWorkFlowDetails(response.data);
    }
  };
  const getCoachingFilters = async () => {
    var loginId = localStorage.getItem("currentUser");
    const response = await getDataFromApi(coachingFilter);
    if (response && response.status == 200 && response.data != null) {
      // let category = []
      const eventtypes = response?.data?.event_type.map((category) => {
        return { id: category, name: category };
      });
      setGetEventType(eventtypes)
      const coachingtypes = response?.data?.coaching_type.map((coaching) => {
        return { id: coaching, name: coaching };
      });
      setGetCoaching(coachingtypes)

      const medium = response?.data?.medium.map((medium) => {
        return { id: medium, name: medium };
      });
      setMedium(medium)

      const coachingAction = response?.data?.coaching_action_groups.map((coaching_action) => {
        return { id: coaching_action.id, name: coaching_action.name };
      });
      setCoachingAction(coachingAction)
    }
  }

  const handleElementClcik = async (e, id) => {
    workflowStepList.map((opt) => {
      if (opt.id == id) {
        if (
          opt.pdf_required &&
          (opt.pdf_required == "true" || opt.pdf_required == true)
        ) {
          var pdf_required = "True";
        } else {
          var pdf_required = "False";
        }
        setFormdata((formData) => ({
          ...formData,
          ["event_step_name"]: opt.step_name,
          ["Description"]: opt.description,
          ["nameRadio"]: pdf_required,
          ["user_usergroup"]: opt.user_usergroup,
        }));
      }
    });

    addmoreEventsetOpen(true);
    seteditmore(true);
    seteditmoreId(id);
  };


  useEffect(() => {
    geteventDetails();
    getworkFlowEvent();
    getCoachingFilters();
    getCoachingTypeFilters()
   

  }, []);
  useEffect(() => {
    getusergroup();
  }, []);

  var eventdesignertable = false;
  const getdatatable = async () => {
    $(document).ready(function () {
      setTimeout(() => {
        if ($.fn.dataTable.isDataTable("#eventdesignertable")) {
          eventdesignertable = $("#eventdesignertable").DataTable({
            pageLength: 20,
          });
        } else {
          eventdesignertable = $("#eventdesignertable").DataTable({
            paging: false,
          });
        }
      }, 1000);

      // setTimeout(function () {
      //   $('#eventdesignertable').DataTable({
      //     "pageLength": 20,
      //   });
      //   eventdesignertable = true
      // }, 1000);
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0
      ? Math.max(
        0,
        (1 + page) * rowsPerPage - eventDetails.length

      )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [pageOne, setPageOne] = React.useState(0);
  const [rowsonePage, setRowsonepage] = React.useState(10);
  const emptyoneRows =
    page > 0
      ? Math.max(
        0,
        (1 + page) * rowsPerPage - workflowDetails.length

      )
      : 0;
  const handleOnepage = (e, newpage) => {
    setPageOne(newpage);
  };
  const handleChangeOnePerPage = (event) => {
    setRowsonepage(parseInt(event.target.value, 10));
    setPageOne(0);
  };
  const handleSearchChange = (e, tab) => {
    if (tab === 'workFlowStep') {
      const duplicateArray = [...workflowDetails];
      if (e.target.value.length === 0) {
        setSearchData([]);
        setSearchQuery('');
      } else {
        setSearchQuery(e?.target?.value?.toLowerCase());
        const filteredData = duplicateArray.filter(item => {
          return (
            item.step_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.user_groups.find(item => item.name.toLowerCase().includes(e.target.value.toLowerCase())) ||
            (item.company_name !== null && item.company_name.toLowerCase().includes(e.target.value.toLowerCase()))
          );
        });
        setSearchData(filteredData)
      }
    } else {
      const duplicateArray = [...getCoachingDetails];
      if (e.target.value.length === 0) {
        setSearchData([]);
        setSearchQuery('');

      } else {
        setSearchQuery(e.target.value.toLowerCase());
        const filteredData = getCoachingDetails.filter(item => {
          return (
            item.coaching_workflow_name.includes(e.target.value.toLowerCase())
          );
        });
        setSearchData(filteredData)
      }
    }
  };
  const moreActions = (i, tabCell, event) => {
    event.stopPropagation();
    if (i >= 0 && tabCell === 'cell') {
      setShowDrpdwn(i);
    } else {
      setShowDrpdwn('');

    }
  }
  const elementRef = useRef(null);

  const handleClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setShowDrpdwn('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Box className="main-box mar-t-24 grey-bg pad-b-50">
        <Grid container spacing={3} className="main-grid">
          <Grid item md={6} className="main-title">
            <h4>Event Type</h4>
          </Grid>
          <Grid item md={6} className="breadcrumb">
            <h6>
              <span className="pointer" onClick={() => navigate("/dashboard")}>
                Home /
              </span>{" "}
              Event Type
            </h6>
          </Grid>
        </Grid>
        {/* <AlertMessage
          alert={alert}
          alertMessage={alertMessage}
          confirm={confirm}
          alertType={alertType}
        /> */}
        <Grid container spacing={3} className="search-form-main">
          <Grid
            item
            md={12}
            className="search-form-block  text-align-right mar-b-24"
          ></Grid>
        </Grid>

        <Grid container spacing={3} className="user-list-main">
          <Grid item md={12} className="user-list-block">
            <div className="user-list-start">
              <div className="user-list-top-bar" ref={elementRef}>
                <Grid container className="search-form-main">
                  <Grid item md={6} className="main-title-user respo-w-fit">
                    {/* <h4 className='font-14'>List of Event workflow</h4> */}

                    <Stack direction="row" spacing={2}>
                      {/* < Button
              variant='contained'
              color='primary'
              className='whitebg custom-btn'
              onClick={() => navigate("/event_designer")}>
              WorkFlow
            </Button> */}

                      {/* < Button
              variant='contained'
              color='primary'
              className='whitebg custom-btn'
              onClick={() => navigate("/event_designer/workflowsteps")}>
              WorkFlow Steps
            </Button> */}

                      <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Event Type" {...a11yProps(0)}></Tab>
                        
                      </Tabs>
                    </Stack>
                  </Grid>
                  {localStorage.getItem('userRole') !== 'Company User' &&
                    <Grid item md={6} className="main-title-user respo-w-fit">
                      <Stack className="end" direction="row" spacing={2}>
                     

                        <Button
                          // disabled
                          variant="contained"
                          color="primary"
                          className="whitebg custom-btn"
                          // onClick={() =>
                          //   navigate("/event_designer/add_eventdesigner")
                          // }
                          onClick={addCoachandleOpen}
                          sx={{color:"white"}}
                        >
                          Add Event Type{" "}
                        </Button>
                      </Stack>
                    </Grid>
                  }
                </Grid>
              </div>
              {/* onClick={()=>moreActions('','table')} */}
              <TabPanel value={value} index={0}>
                <div className="user-list-bottom">
                  <Grid container spacing={3} className="user-list-main-block">
                    <Grid item md={12} className="user-list-right">
                      <div >
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => handleSearchChange(e, 'eventDesigner')}
                          placeholder="Search..."
                          style={{ float: 'right', margin: '10px', height: '40px', width: '25%', paddingLeft: '10px' }}
                        />
                        <Table
                          id="companytable" /*  data-order='[[ 0, "desc" ]]' */
                        >
                          <div className="tab-overflow-scroll overflow-scroll">                           
                           <TableHead className="sticky-table-header">
                           <TableRow>
                              {/* <TableCell className="d-none">Company ID</TableCell> */}
                              <TableCell style={{ width: "6%" }}>S.No</TableCell>
                              <TableCell style={{ width: "27%" }}>Event Type</TableCell>
                              <TableCell style={{ width: "15%" }}>Priority</TableCell>
                              <TableCell style={{ width: "17%" }}>Category</TableCell>
                              <TableCell style={{ width: "17%" }}>Company Name</TableCell>
                              {/* <TableCell style={{ width: "6%" }}>Coaching Action Group</TableCell> */}
                              <TableCell style={{ width: "35%"}}> Action</TableCell>
                            </TableRow>
                          </TableHead>
                            <TableBody>
                              {getCoachingDetails &&
                                getCoachingDetails?.length > 0 &&
                                (rowsonePage > 0
                                  ? (searchQuery?.length === 0 && getCoachingDetails?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )) || (searchQuery?.length > 0 && searchData?.slice(
                                    pageOne * rowsonePage,
                                    pageOne * rowsonePage + rowsonePage)
                                  )
                                  : ((searchQuery?.length === 0 && getCoachingDetails) || (searchQuery?.length > 0 && searchData))
                                )
                                  .map((item, i) => (
                                    <TableRow key={item?.id}>
                                     <TableCell style={{ width: "6%" }}>{item?.id}</TableCell>
                                      <TableCell style={{ width: "28%" }}>{item?.event_type} </TableCell>
                                      <TableCell  style={{ width: "27%" }}>{item?.priority}</TableCell>
                                      <TableCell style={{ width: "15%" }}>{item?.category}</TableCell> 
                                      <TableCell style={{ width: "17%" }}>{item?.company_name}</TableCell>                                     
                                      {/* <TableCell style={{ width: "6%" }}>
                                        {item?.coaching_action_group?.name}
                                      </TableCell> */}
                                      <TableCell  style={{ width: "35%",whiteSpace:"nowrap"  }}className="text-center" >
                                        <p className="justify" style={{ marginTop: "10px", marginBottom: '10px' }}
                                        >
                                          <span
                                            className="eye-icon"
                                            // onClick={addCoachViewandleOpen}
                                            onClick={e => handleView(item.id, 'view', e)}
                                          //   onClick={() =>
                                          //     navigate(
                                          //       "/company_management/view_company/" +
                                          //       company.id
                                          //     )
                                          //   }
                                          >
                                            <img className="table-icons" src={Eye} />
                                          </span>
                                          <img
                                            className="table-icons pointer"
                                            src={Pencil}
                                            onClick={e => handleView(item.id, 'edit', e)}
                                          //   onClick={() =>
                                          //     navigate(
                                          //       "/company_management/edit_company/" +
                                          //       company.id
                                          //     )
                                          //   }
                                          />
                                          <img
                                            className="table-icons pointer"
                                            src={Trash}
                                            onClick={(e) => handleDeleteOpens(item.id, e)}
                                          //   onClick={() =>
                                          //     navigate(
                                          //       "/company_management/edit_company/" +
                                          //       company.id
                                          //     )
                                          //   }
                                          />
                                          {/* <img className="table-icons pointer" src={Trash}  onClick={() => handleDeleteOpen(company.id)} /> */}
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </div >

                          <tfoot>
                            <tr>
                              <TablePagination
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  25,
                                  { label: "All", value: -1 },
                                ]}
                                colSpan={7}
                                count={getCoachingDetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                  select: {
                                    "aria-label": "rows per page",
                                  },
                                  actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                  },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={deleteOpen}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"  Are you sure you want to Delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteconfirm}
              color="primary"
              variant="contained"
              className="whitebg"
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="whitebg"
              onClick={handleDeleteClose}
              color="primary"
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteOpens}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"  Are you sure you want to Delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteConfirm}
              color="primary"
              variant="contained"
              className="whitebg"
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="whitebg"
              onClick={handleDeleteClose}
              color="primary"
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={workdeleteOpen}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" Are you sure you want to Delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteWork}
              color="primary"
              variant="contained"
              className="whitebg"
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="whitebg"
              onClick={handleWorkDeleteClose}
              color="primary"
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
        <DialogForm
          mode={dialogMode}
          open={dialogOpen}
          onClose={handleDialogClose}
          formData={formData}
          data={data}
        // handleSubmit={handleSubmit}
        />

        <Dialog
          open={addOpen}
          onClose={coachandleClose}
          aria-labelledby="form-dialog-title"
          aria-describedby="modal-modal-description"
          fullWidth
          maxWidth="md"
        >
          <AlertMessage
            alert={alert}
            alertMessage={alertMessage}
            confirm={confirm}
            alertType={alertType}
          />
          <ValidatorForm
            onSubmit={handleSubmitSearch}
            onError={() => null}
            className=""
          >
            <MuiDialogTitle
              className="pad-l-17"
              id="form-dialog-title"
            // onClose={coachandleClose}
            >
           

              {isView ? (isEdit ? "Edit Event Type" : "View Event Type") : (isEdit ? "Edit Event Type" : "Add Event Type")}
              <img
                src={CloseBtn}
                className="discard-close-pointer"
                onClick={coachandleClose}
              />
            </MuiDialogTitle>
            <DialogContent className="pad-l-r-t-b">
              <Grid className="">
                <Grid container spacing={2}>
               
                  <Grid item md={6} className="search-form-block respo-pad-t">
                    <label className="event-label">Event Type</label>
                    {/* <AutoComplete
                      multiple
                      className="dropdown gm-t-10 w-100 mar-b-0"
                      fullWidth
                      defaultValue={getSelectedItem(
                        formdata.event_type,
                        getEventType, 1
                      )}
                      disabled={isView}
                      onChange={(event, value) =>
                        changeDropdownValue("event_type", value)
                      }
                      // disabled={veiwMode}
                      getOptionLabel={(option) => option.name}
                      options={getEventType}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                          label="Select"
                          name="event_type"
                          placeholder="Select"
                          value={formdata.event_type}
                        //value={formdata.vehicle_grouping || ''}
                        />
                      )}
                    /> */}
   <TextField
  className="dropdown gm-t-10 w-100 mar-b-0"
  label="Select"
  name="event_type"
  placeholder="Select"
  value={formdata.event_type || ''}
  onChange={(event) => changeDropdownValue("event_type", event.target.value)}
  disabled={isView}
/>
                  </Grid>
                  <Grid item md={6} className="search-form-block respo-pad-t">
                    <label className="event-label">Priority</label>
                    <Autocomplete
    className="dropdown gm-t-10 w-100 mar-b-0"
    fullWidth
    options={[
        { label: 'P0', id: 'P0' },
        { label: 'P1', id: 'P1' },
        { label: 'P2', id: 'P2' },
        { label: 'P3', id: 'P3' }
    ]}
    defaultValue={getSelectedItem(formdata.priority, [
        { label: 'P0', id: 'P0' },
        { label: 'P1', id: 'P1' },
        { label: 'P2', id: 'P2' },
        { label: 'P3', id: 'P3' }
    ])}
    value={getSelectedItem(formdata.priority, [
        { label: 'P0', id: 'P0' },
        { label: 'P1', id: 'P1' },
        { label: 'P2', id: 'P2' },
        { label: 'P3', id: 'P3' }
    ])}
    getOptionLabel={(option) => option.label || ''}
    onChange={(event, value) => changeDropdownValue("priority", value)}
    filterSelectedOptions
    renderInput={(params) => (
        <TextField
            {...params}
            className="search-dropdown custom-input border-none"
            label='Priority'
            name='priority'
            placeholder='Select Priority'
        />
    )}
    disabled={isView}
/>

                  </Grid>

                  <Grid item md={6} className="search-form-block respo-pad-t">
                    <label className="event-label">Company</label>
                    <AutoComplete
  className="dropdown gm-t-10 w-100 mar-b-0"
  fullWidth
  options={companyOptions}
  defaultValue={getSelectedItem(formdata.company_name, companyOptions, '', 1)}
  value={companyOptions.find(option => option.id === formdata.company_name) || null}
  getOptionLabel={(option) => option.label || ''}  // Define how to get the label from an option
  onChange={(event, value) => changeDropdownValue("company_name", value)}
  filterSelectedOptions
  renderInput={(params) => (
    <TextField
      {...params}
      className="search-dropdown custom-input border-none"
      label='Company'
      name='company_name'
      placeholder='Company'
      // validators={["required"]}
      // errorMessages={["this field is required"]}
      value={formdata.company_name}
    />
  )}
  disabled={isView}
/>
                  </Grid>
                  <Grid item md={6} className="search-form-block respo-pad-t">
                    <label className="event-label">Catergory</label>
                    <AutoComplete
                    multiple
                    className="dropdown gm-t-10 w-100 mar-b-0"
                    fullWidth
                    defaultValue={getSelectedItem(
                      formdata.category,
                      categoryopt,
                      1
                    )}
                    // disabled={disabled}
                    getOptionLabel={(option) => option.label}
                    options={categoryopt}
                    onChange={(event, value) =>
                      changeDropdownValue("category", value)
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                        label="Select"
                        name="category"
                        placeholder="Select"
                        value={formdata.category}
                      />
                    )}
                    disabled={isView}
                  />
                  </Grid>
                </Grid>
              
              </Grid>

            </DialogContent>
            <DialogActions>
              {isView === true ? null : <><Button
                variant="contained"
                color="primary"
                className="whitebg custom-btn"
                type="submit"
                disabled={isView && true}
              >
                SAVE
              </Button>
                <Button variant="outlined" color="error"
                  // type="button contact-button"
                  className="button1 cancel-btn pointer mar-r-15 mar-r-25"
                  onClick={coachandleClose}
                  disabled={isView && true}
                >
                  Back
                </Button>
              </>
              }
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Modal
          open={addmoreEventOpen}
          onClose={addmoreEventhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            container
            sx={{
              width: "auto",
              height: "auto",
              padding: 0,
            }}
          >
            <Grid container spacing={4}>
              <Grid item md={4} xs={8} className="snap-form pl-5 pd-btm">
                <h4 className="discard-h add-event-add">
                  {editmore
                    ? "UPDATE EVENT WORKFLOW STEPS"
                    : "ADD EVENT WORKFLOW STEPS"}{" "}
                  <img
                    src={CloseBtn}
                    className="snap-close"
                    onClick={addmoreEventhandleClose}
                  />
                </h4>

                {/* <h6 className="font-12 font-underline event-text-align-right view-discard">View Events</h6> */}
                <Grid item md={12} xs={12}>
                  <ValidatorForm
                    onSubmit={handleSubmitStep}
                    onError={() => null}
                    className="frm-cs"
                  >
                   
                    <Grid item md={12} xs={12} className="flx-2-prt">
                      <label className="pad-l-0 discard-txt pt-15 mar-b-10">
                        Workflow Step
                      </label>
                      <AutoComplete
                        className='dropdown gm-t-10 w-100 mar-b-0 '
                        fullWidth
                        defaultValue={getSelectedItem(
                          formdata.user_usergroup,
                          usergroupsOpt
                        )}
                        onChange={(event, value) =>
                          changeDropdownValue("user_usergroup", value)
                        }
                        getOptionLabel={(option) => option.label || ""}

                        options={usergroupsOpt}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className='search-dropdown custom-input dark-grey-bg dark-grey-color border-none'
                            label='Select'
                            name='user_usergroup'
                            placeholder='Select'
                            value={formdata.user_usergroup}
                          />
                        )}
                      />
                    </Grid>
                    <button
                      type="button home-button"
                      className="button1 discard-btn mar-r--10"
                    >
                      {editmore ? "Update" : "Add"}
                    </button>
                    <button
                      type="button contact-button"
                      className="button1 cancel-btn red-btn-cancel"
                      onClick={addmoreEventhandleClose}
                    >
                      Back
                    </button>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default EventType;
